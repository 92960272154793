import {
  Container,
  Dialog, DialogContent, DialogTitle, IconButton, Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { TermsOfServiceJoist } from './TermsOfServiceJoist';
import { PrivacyPolicy } from './PrivacyPolicy';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
})) as typeof Dialog;

export function TermsOfServiceWrapperJoist() {
  const { t } = useTranslation();
  const [tosOpen, setTosOpen] = useState(false);
  const theme = useTheme();
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);

  const handleTosClose = () => setTosOpen(false);
  const handleTosOpen = () => setTosOpen(true);
  const handlePrivacyPolicyClose = () => setPrivacyPolicyOpen(false);
  const handlePrivacyPolicyOpen = () => setPrivacyPolicyOpen(true);

  return (
    <Container maxWidth='sm'>
    <Typography component={'div'} sx={{
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      textAlign: 'center',
      fontSize: theme.spacing(1.375),
      color: theme.palette.color2.main,
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
      },
    }}>{t('home.footerEdge')}<Typography component={'div'} sx={{
      textAlign: 'center', fontSize: '11px', color: theme.palette.color2.main, display: 'inline',
    }}><span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleTosOpen}>{t('home.tosHeading')}</span> {t('onboarding.common.and')} <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handlePrivacyPolicyOpen}>{t('home.privacyPolicyHeading')}</span>.
          </Typography></Typography>
        <BootstrapDialog
          onClose={handleTosClose}
          aria-labelledby="customized-dialog-title"
          open={tosOpen}
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <IconButton
              aria-label="close"
              onClick={handleTosClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: () => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TermsOfServiceJoist />
          </DialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          onClose={handlePrivacyPolicyClose}
          aria-labelledby="customized-dialog-title"
          open={privacyPolicyOpen}
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <IconButton
              aria-label="close"
              onClick={handlePrivacyPolicyClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: () => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <PrivacyPolicy />
          </DialogContent>
        </BootstrapDialog>
    </Container>
  );
}
