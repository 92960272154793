import {
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import ContentHome from '../Content/ContentHome';
import { getMemberInformation } from '../../store/thunks/onboardingThunk';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
// import VerificationEmailCard from '../../common/VerificationEmailCard';
// import LennoxAccountNumberCard from '../../common/LennoxAccountNumberCard';
import { useTracking } from '../../common/useTracking';
import { HDSetupFlowCard } from './HDOnboardingFlow/HDSetupFlowCard';
import { selectCompanyEnrollmentInformation, selectNaviagtionFromOnboarding, updateNavigationFromOnboarding } from '../../store/slices/onboardingSlice';
import { useOnboardEventTracking } from '../../common/useOnboardEventTracking';

// optional card style
/* const optionalCardStyle = (theme: Theme) => ({
  marginTop: '24px',
  marginBottom: '0px',
  height: 'fit-content',
} as const); */

export function Dashboard() {
  // const { verificationEmailFlag, hdOnboardingForSNFlag } = useFeatureFlags();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(() => theme.breakpoints.up('md'));
  // const location = useLocation();
  // const navigate = useNavigate();
  const trackEvent = useTracking();
  const companyEnrollmentInformation = useAppSelector(selectCompanyEnrollmentInformation);
  const navigationFromOnboarding = useAppSelector(selectNaviagtionFromOnboarding);
  const trackOnboardEvent = useOnboardEventTracking();

  useEffect(() => {
    dispatch(getMemberInformation({ trackEvent }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (navigationFromOnboarding && companyEnrollmentInformation?.status) {
      dispatch(updateNavigationFromOnboarding(false));
      // track user lands on home feed after onboarding
      trackOnboardEvent('landed-on-the-home-feed-after-onboarding');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyEnrollmentInformation, navigationFromOnboarding]);

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 4, md: 4 }}
    >
      {/* TODO: enable this after trending topics is added */}
      {/*
            {location.state?.fromOnboarding && <Grid item xs={4} sm={4} md={4}>
              <Box
                sx={{
                  background:
                  'radial-gradient(104.99% 53.33% at 50% 46.67%, #78B0C5 0%, #569BB4 100%)',
                  borderRadius: '26px',
                  paddingLeft: '24px',
                  paddingTop: '56px',
                  display: 'flex',
                  flexDirection: 'column',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate('/rewards');
                }}
              >
                <ServiceFusionLogo />
                <Box sx={{
                  fontWeight: '600',
                  color: '#FFFFFF',
                  letterSpacing: '0.335977px',
                  paddingRight: '20px',
                  fontSize: '30px',
                  marginTop: '18px',
                  lineHeight: '37px',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '20px',
                    lineHeight: '27px',
                    paddingRight: '0px',
                    letterSpacing: '0px',
                  },
                }}>
                  {'Welcome to Service Nation Edge where you earn and learn'}
                </Box>
                <Box sx={{
                  fontWeight: 500,
                  fontSize: '18px',
                  lineHeight: '24px',
                  letterSpacing: '0.0898333px',
                  color: '#FFFFFF',
                  marginTop: '8px',
                  paddingRight: '20px',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '16px',
                    paddingRight: '0px',
                  },
                }}>
                  {'Watch your rebates grow,
                  receive quarterly payments, and receive industry news.'}
                </Box>
                <Box sx={{
                  fontWeight: '400',
                  fontSize: '12px',
                  lineHeight: '12px',
                  letterSpacing: '-0.287467px',
                  color: '#FFFFFF',
                  marginTop: '24px',
                  paddingRight: '20px',
                  marginBottom: '36px',
                }}>
                  {'Exclusions on items may apply.'}
                </Box>
              </Box>
            </Grid>}
            */}
      {!isDesktopScreen &&
        <Grid item xs={4} sm={4} md={4} sx={{ paddingTop: '0px !important' }}>
          <HDSetupFlowCard hideDoneState={true} />
        </Grid>
          }
      {/* verificationEmailFlag &&
        <Grid item xs={4} sm={4} md={4} sx={{ paddingTop: '0px !important' }}>
          <VerificationEmailCard
            optionalCardStyle={optionalCardStyle}
            optionalBtnStyles={{
              background: theme.palette.secondary.main,
              borderRadius: '30px',
              '&:hover': {
                backgroundColor: theme.palette.secondary.main,
              },
              '&:disabled': {
                backgroundColor: theme.palette.secondary.main,
              },
            }}
            emailInputStyles={{
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: theme.palette.secondary.main,
                },
              },
            }}
          />
          </Grid> */}
      {/* <Grid item xs={4} sm={4} md={4} sx={{ paddingTop: '0px !important' }}>
        <LennoxAccountNumberCard
          optionalCardStyle={optionalCardStyle}
          optionalBtnStyles={{
            background: theme.palette.secondary.main,
            borderRadius: '30px',
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            '&:disabled': {
              backgroundColor: theme.palette.secondary.main,
            },
          }}
          inputStyles={{
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: theme.palette.secondary.main,
              },
            },
          }}
        />
        </Grid> */}
      <ContentHome contentViewBaseUrl={'/home'} />
    </Grid>
  );
}
