import {
  Box, Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSolutionOrg } from '../../../../config/useSolutionOrg';
import { QuestionOption } from '../../../../common/models/Question';
import { useLogo } from '../../../../config/useLogo';
import { useFeatureFlags } from '../../../../config/FeatureFlagsProvider';
import { ReactComponent as NewBadge } from '../../../../assets/images/new_badge.svg';
import { ReactComponent as BehrLogo } from '../../../../assets/images/behr_logo.svg';

export interface BannerLogoProps {
  activePartners: QuestionOption[] | undefined
}
export function BannerLogos({ activePartners }: BannerLogoProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScrren = useMediaQuery(() => theme.breakpoints.down('sm'));
  const {
    onlyHDOnboardingHomeDepot: OnlyHDOnboardingHomeDepotLogo,
    main: Logo,
  } = useLogo();
  const { solutionOrgHasOnlyHomeDepot } = useSolutionOrg();
  const { behrFlag } = useFeatureFlags();

  const AllPartners = () => (
    <Grid container justifyContent="center" spacing={{
      xs: 4, sm: 1, md: 1, lg: 1.5,
    }} columns={{
      xs: 12, sm: 12, md: 12, lg: 12,
    }}
      rowSpacing={{
        lg: 2, md: 2, sm: 2, xs: 3,
      }}
      sx={{
        alignItems: 'center',
        mb: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacing(4),
        },
      }}
    >
      {activePartners &&
        activePartners.map((item, index) => (
          <Grid item xs={6} sm={4} md={4} lg={4} key={item.value}
            sx={{
              alignItems: 'center',
              [theme.breakpoints.down('sm')]: {
                paddingRight: theme.spacing(1.5),
              },
            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <img src={item.whiteImageUrl} alt={item.display} style={{ maxWidth: '90%' }} />
            </Box>
          </Grid >
        ))}
    </Grid >
  );

  const OnlyHomeDepotPartner = () => (
    <>
      {/* {activePartners && activePartners.length > 0 && */}
      {/* commenting this as no info about activePartners on login */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mb: theme.spacing(1.5),
          [theme.breakpoints.down('sm')]: {
            marginBottom: behrFlag ? theme.spacing(1.5) : theme.spacing(4),
          },
        }}
      >
        <Box sx={{
          width: '45%',
          padding: theme.spacing(2),
          paddingTop: theme.spacing(4),
          paddingBottom: theme.spacing(4),
          borderRadius: theme.spacing(1),
          background: theme.palette.color12.main,
          marginRight: theme.spacing(2),
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(3),
          },
        }}>
          {/* TODO: consider adding this image to the home depot option on BE */}
          {OnlyHDOnboardingHomeDepotLogo &&
            <OnlyHDOnboardingHomeDepotLogo
              width={isSmallScrren ? theme.spacing(9) : theme.spacing(16)}
              height={isSmallScrren ? theme.spacing(9) : theme.spacing(16)}
              title={
                activePartners?.[0]?.display || 'home depot'
              } />}
        </Box>
        {Logo &&
          <Box sx={{
            padding: behrFlag ? theme.spacing(2) : theme.spacing(1),
            borderRadius: theme.spacing(1),
            background: theme.palette.color12.main,
            width: '45%',
            [theme.breakpoints.down('sm')]: {
              padding: behrFlag ? theme.spacing(1) : theme.spacing(3),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}>
            {behrFlag ? <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                height: '100%',
              }}>
              <NewBadge
                width={isSmallScrren ? theme.spacing(3) : theme.spacing(5.5)}
                height={isSmallScrren ? theme.spacing(3) : theme.spacing(5.5)}
              />
              <BehrLogo
                width={isSmallScrren ? theme.spacing(10) : theme.spacing(16)}
                height={isSmallScrren ? theme.spacing(10) : theme.spacing(16)}
                style={{
                  marginTop: isSmallScrren ? theme.spacing(2) : theme.spacing(3),
                  marginLeft: isSmallScrren ? theme.spacing(1.5) : theme.spacing(0),
                }}
                title='behr' />
            </Box> : <Box
              sx={{
                mt: theme.spacing(8),
                [theme.breakpoints.down('sm')]: {
                  mt: theme.spacing(0),
                },
              }}
            >
              <Logo
                width={isSmallScrren ? theme.spacing(9) : theme.spacing(20)}
                height={theme.spacing(3)}
                title={'more partners coming'}
              />
              <Box
                sx={{
                  borderRadius: theme.spacing(14),
                  backgroundColor: theme.palette.common.black,
                  color: theme.palette.common.white,
                  pb: theme.spacing(0.5),
                  pt: theme.spacing(0.5),
                  width: '70%',
                  display: 'inline-block',
                  [theme.breakpoints.down('sm')]: {
                    width: '95%',
                  },
                }}
              >
                <Typography variant='h10' sx={{
                  [theme.breakpoints.down('sm')]: {
                    fontSize: theme.spacing(1),
                  },
                }}>
                  + {t('home.banner.morePartners')}
                </Typography>
              </Box>
            </Box>
            }
          </Box>
        }
      </Box>

      {/* } */}
    </>
  );

  return (
    <>{
      solutionOrgHasOnlyHomeDepot ? <OnlyHomeDepotPartner /> : <AllPartners />
    }
      {behrFlag &&
        <Box sx={{
          padding: theme.spacing(1.5),
          borderRadius: theme.spacing(1),
          background: theme.palette.color12.main,
          [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1.5),
          },
        }}>
          <Typography variant='h6'
            color={theme.palette.common.white}>+ {t('home.banner.morePartnersComingSoon')}</Typography>
        </Box>
      }
    </>);
}
