import { createTheme } from '@mui/material/styles';
import React from 'react';
import {
  UNKNOWN_DOMAIN, JOIST_DOMAIN, SERVICE_FUSION_DOMAIN, getAppDomain,
  EVERPRO_DOMAIN,
} from '../config/domains';

// declare custom palette colors here
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    // example custom color
    color1: { main: string; };
    color2: { main: string; };
    color3: { main: string; };
    color4: { main: string; };
    color5: { main: string; };
    color6: { main: string; };
    color7: { main: string; };
    color8: { main: string; };
    color9: { main: string; };
    color10: { main: string; };
    color11: { main: string; };
    color12: { main: string; };
    color13: { main: string; };
    color14: { main: string; };
    color15: { main: string; };
    color16: { main: string; };
    color17: { main: string; };
    color18: { main: string; };
    green: { main: string; };
    grey56: { main: string; };
    grey2: { main: string; };
    gradient: { main: string, dark: string };
  }

  interface PaletteOptions {
    color1?: { main: string; };
    color2?: { main: string; };
    color3?: { main: string; };
    color4?: { main: string; };
    color5?: { main: string; };
    color6?: { main: string; };
    color7?: { main: string; };
    color8?: { main: string; };
    color9?: { main: string; };
    color10?: { main: string; };
    color11?: { main: string; };
    color12?: { main: string; };
    color13?: { main: string; };
    color14?: { main: string; };
    color15?: { main: string; };
    color16?: { main: string; };
    color17?: { main: string; };
    color18?: { main: string; };
    green?: { main: string; };
    grey56?: { main: string; };
    grey2: { main: string; };
    gradient: { main: string, dark: string };
  }
}

// declare custom typography variants
declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
    h9: React.CSSProperties;
    h10: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    body5?: React.CSSProperties;
    body6?: React.CSSProperties;
    body7?: React.CSSProperties;
    h7?: React.CSSProperties;
    h8?: React.CSSProperties;
    h9?: React.CSSProperties;
    h10?: React.CSSProperties;
    h11?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    body5: true;
    body6: true;
    body7: true;
    h7: true;
    h8: true;
    h9: true;
    h10: true;
    h11: true;
  }
}

// A custom theme to be used in the app
const commonTheme = createTheme({
  palette: {
    primary: { main: '#FE2C55' },
    common: { white: '#FFFFFF', black: '#000000' },
    grey: { 100: '#f5f5f5' },
    grey2: { main: '#DADADA' },
    error: { main: '#d32f2f' },
    color1: { main: '#008000' },
    color2: { main: '#8A8A8D' },
    color3: { main: '#404040' },
    color4: { main: '#C7C7CC' },
    color5: { main: '#F7F5F7' },
    color6: { main: '#585C60' },
    color7: { main: '#FFFEFE' },
    color8: { main: '#CDD6DA' },
    color9: { main: '#4A89DC' },
    color10: { main: '#7993A5' },
    color11: { main: '#8A898E' },
    color12: { main: '#222222' },
    color13: { main: '#A6A6A6' },
    color14: { main: '#FF6600' },
    color15: { main: '#3C3C4399' },
    color16: { main: '#32302F' },
    color17: { main: '#181818' },
    color18: { main: '#bbb' },
    green: { main: '#00ED00' },
    grey56: { main: '#8F8F8F' },
    gradient: { main: '#82aee7', dark: '#4A89DC' },
    // color3: { main: 'rgba(60, 60, 67, 0.6)' },
  },
  typography: {
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 16,
      fontWeight: 500,
    },
    body3: {
      fontSize: '17px',
      fontWeight: '550',
    },
    body4: {
      fontWeight: 700,
      fontSize: '18px',
    },
    body5: {
      fontWeight: 500,
      fontSize: '18px',
    },
    body6: {
      fontWeight: 700,
      fontSize: '23px',
    },
    body7: {
      fontWeight: 500,
      fontSize: '14px',
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,
    },
    h3: {
      fontSize: 20,
      fontWeight: 400,
    },
    h4: {
      fontWeight: 600,
      fontSize: '18px',
    },
    h5: {
      fontSize: '20px',
      fontWeight: 500,
    },
    h6: {
      fontSize: '14px',
      fontWeight: 600,
    },
    h7: {
      fontSize: '26px',
      fontWeight: 400,
    },
    h8: {
      fontSize: '30px',
      fontWeight: 700,
    },
    h9: {
      fontWeight: 600,
      fontSize: '22px',
    },
    h10: {
      fontWeight: 500,
      fontSize: '12px',
    },
    h11: {
      fontWeight: 700,
      fontSize: '24px',
    },
  },
  spacing: 8, // spacing as multiples of 8px
  breakpoints: { // TODO: configure these values according to business requirement
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottomRightRadius: 8,
          borderBottomLeftRadius: 8,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          textAlign: 'left',
          borderColor: 'white',
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          backgroundColor: 'white',
        },
      },
    },
    // map custom typography variants to p component so they are rendered as paragraphs
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: 'p',
          body4: 'p',
          body5: 'p',
          h9: 'p',
          h10: 'p',
        },
      },
    },
  },
});

const joistTheme = createTheme(commonTheme, {
  // Add joist specific themes here
  palette: {
    secondary: { main: commonTheme.palette.color1.main, light: '#0080000A' },
    gradient: { main: '#35b735', dark: commonTheme.palette.color1.main },
  },
});

const sfTheme = createTheme(commonTheme, {
  // Add service nation specific theme here
  palette: {
    secondary: { main: '#4A89DC', light: '#4A89DC0A' },
    gradient: { main: '#82aee7', dark: '#4A89DC' },
  },
});

const everProTheme = createTheme(commonTheme, {
  // Add everPro specific theme here
  palette: {
    secondary: { main: '#FFB301', light: '#FFB3010A' },
    gradient: { main: '#e8bd5a', dark: '#FFB301' },
  },
});

const domainThemes = {
  [JOIST_DOMAIN]: joistTheme,
  [SERVICE_FUSION_DOMAIN]: sfTheme,
  [EVERPRO_DOMAIN]: everProTheme,
  // add all themes here as comma separated. Order matters here since it's doing a deep merge
  [UNKNOWN_DOMAIN]: commonTheme,
};

// determine current domain of app
const appDomain = getAppDomain();

export default domainThemes[appDomain];
