import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Typography, useTheme,
} from '@mui/material';
import { ReactComponent as BehrLogo } from '../assets/images/behr_logo.svg';
import { ReactComponent as KilzLogo } from '../assets/images/kilz_logo.svg';
import { ReactComponent as PendingBadge } from '../assets/images/pending_badge.svg';
import { ReactComponent as CompletedBadge } from '../assets/images/completed_badge.svg';
import {
  selectHomeDepotMemberRelation,
  selectGetHomeDepotMemberRelationState,
} from '../store/slices/dashboardSlice';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { HDVerificationStatus } from './enums';
import { getHomeDepotMemberRelation } from '../store/thunks/dashboardThunk';
import { BEHR } from './constants';

const partners = [
  {
    name: BEHR,
    logo: BehrLogo,
  },
  {
    name: 'kilz',
    logo: KilzLogo,
  },
];

export default function PartnerEnrollmentCard() {
  const theme = useTheme();
  const { t } = useTranslation();
  const hdMemberRelation = useAppSelector(selectHomeDepotMemberRelation);
  const [verificationStatus, setVerificationStatus] = useState(HDVerificationStatus.Pending);
  const getHomeDepotMemberRelationState = useAppSelector(selectGetHomeDepotMemberRelationState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!hdMemberRelation) {
      dispatch(getHomeDepotMemberRelation());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hdMemberRelation) {
      if (hdMemberRelation?.partnerMemberKey) {
        setVerificationStatus(HDVerificationStatus.Completed);
      } else if (hdMemberRelation?.partnerMemberEmail) {
        setVerificationStatus(HDVerificationStatus.Completed);
      } else {
        setVerificationStatus(HDVerificationStatus.Pending);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hdMemberRelation]);

  return (
    <Box>
      {
        // no need to show the cards if member hasn't selected home depot
        (getHomeDepotMemberRelationState === 'pending' || !hdMemberRelation) ?
          null :
          <Box>
            {
              partners.map((partner) => {
                const PartnerLogo = partner.logo;
                return (
                  <Box key={partner.name} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    padding: theme.spacing(3),
                    border: '1px solid #E5E5E5',
                    borderRadius: theme.spacing(1.6),
                    margin: `${theme.spacing(3)} 0`,
                    backgroundColor: theme.palette.common.white,
                  }}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width: '100%',
                    }}>
                      <PartnerLogo />
                      <Box
                        sx={{
                          marginLeft: theme.spacing(3),
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="h6">
                          {t(`${partner.name}.title`)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          marginLeft: theme.spacing(3),
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {verificationStatus === HDVerificationStatus.Completed ?
                          <CompletedBadge /> : <PendingBadge />}
                      </Box>
                    </Box>
                    <Box sx={{
                      marginTop: theme.spacing(2),
                      width: '100%',
                    }}>
                      {
                        verificationStatus === HDVerificationStatus.Completed ?
                          <Typography variant="body1" sx={{
                            textAlign: 'left',
                            width: '100%',
                          }}>
                            {t('partners.success')} {t(`${partner.name}.rewardsName`)} {t('partners.rewards')}.
                          </Typography> :
                          <Typography variant="body1">
                            {t('partners.description1')} {t(`${partner.name}.rewardsName`)} {t('partners.description2')}
                          </Typography>
                      }

                    </Box>
                    <Box sx={{
                      marginTop: theme.spacing(2),
                    }}>
                      <Typography variant="body2"
                        color={theme.palette.color15.main}
                      >
                        {t('partners.discount')}
                      </Typography>
                    </Box>
                  </Box>
                );
              })
            }
          </Box>
      }
    </Box>
  );
}
