import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateOrUpdatePaymentMethodInput, AddMemberToPartnerInput } from '@optimus/models';
import * as dashboardAPI from '../../api/dashboardAPI';
import { PaymentMethodType, PaymentMethod } from '../../common/models/PaymentMethod';
import { RootState } from '../storeSetup';
import { MemberConfig } from '../../common/models/Member';

export interface ResponseError {
  // TODO: improve this interface to account for expected errors from API call
  errorMessage?: string
}

export interface PayPalAccount {
  id: string;
  type: string;
  status: string;
  memberId: string;
  acknowledgedAt: string;
  meta: {
    paypalAccountId: string;
  }
}

export interface MemberPartnerRelation {
  id: string;
  partnerId: string;
  partnerMemberKey?: string;
  partnerCodeEnteredInMobile?: boolean;
  partnerMemberEmail?: string;
}

export interface YearlyPayments {
  year: number;
  total: string;
}

export const getPayPalAccount = createAsyncThunk<
  // Return type of the payload creator
  PayPalAccount,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'dashboard/getPayPalAccount',
    async (_, { rejectWithValue, getState }) => {
      const { memberSession: { memberId } } = getState().onboarding;
      try {
        const paymentMethods: PaymentMethod[] = await dashboardAPI.getPayPalAccount(memberId);
        const payPalAccount = paymentMethods.find(
          (paymentMethod) => paymentMethod.type === PaymentMethodType.PAYPAL,
        );
        return payPalAccount as PayPalAccount;
      } catch (err) {
        console.log(err);
        const responseError: ResponseError = { errorMessage: 'failed to get payPalAccountId' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const setPayPalAccountId = createAsyncThunk<
  // Return type of the payload creator
  PayPalAccount,
  // First argument to the payload creator
  CreateOrUpdatePaymentMethodInput,
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'dashboard/setPayPalAccountId',
    async (request, { rejectWithValue, getState }) => {
      const { memberSession: { memberId } } = getState().onboarding;
      try {
        const paymentMethod: PaymentMethod = await dashboardAPI
          .setPayPalAccountId(memberId, request);
        const payPalAccount = paymentMethod.type === PaymentMethodType.PAYPAL ?
          paymentMethod : undefined;
        return payPalAccount as PayPalAccount;
      } catch (err) {
        console.log(err);
        const responseError: ResponseError = { errorMessage: 'something went wrong' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const getLennoxMemberRelation = createAsyncThunk<
  // Return type of the payload creator
  MemberPartnerRelation,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'dashboard/getLennoxMemberRelation',
    async (_, { rejectWithValue, getState }) => {
      const { memberSession: { memberId } } = getState().onboarding;
      const lennoxPartnerId = process.env.REACT_APP_LENNOX_PARTNER_ID || '';
      try {
        const lennoxMemberRelation = await dashboardAPI.getLennoxMemberRelation(
          memberId,
          lennoxPartnerId,
        );
        return (lennoxMemberRelation || undefined) as MemberPartnerRelation;
      } catch (err) {
        console.log(err);
        const responseError: ResponseError = { errorMessage: 'failed to get lennoxMemberRelation' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const getHomeDepotMemberRelation = createAsyncThunk<
  // Return type of the payload creator
  MemberPartnerRelation,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'dashboard/getHomeDepotMemberRelation',
    async (_, { rejectWithValue, getState }) => {
      const { memberSession: { memberId } } = getState().onboarding;
      const hdPartnerId = process.env.REACT_APP_HOME_DEPOT_PARTNER_ID || '';
      try {
        const hdMemberRelation = await dashboardAPI.getHomeDepotMemberRelation(
          memberId,
          hdPartnerId,
        );
        return (hdMemberRelation || undefined) as MemberPartnerRelation;
      } catch (err) {
        console.log(err);
        const responseError: ResponseError = { errorMessage: 'failed to get homeDepotMemberRelation' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const setLennoxMemberKey = createAsyncThunk<
  // Return type of the payload creator
  MemberPartnerRelation,
  // First argument to the payload creator
  { memberId: string, partnerId: string, data: AddMemberToPartnerInput },
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
  }>(
    'dashboard/setLennoxMemberKey',
    async (request, { rejectWithValue }) => {
      try {
        const lennoxMemberRelation = await dashboardAPI
          .setLennoxMemberKey(request.memberId, request.partnerId, request.data);
        return (lennoxMemberRelation || undefined) as MemberPartnerRelation;
      } catch (err) {
        console.log(err);
        const responseError: ResponseError = { errorMessage: 'something went wrong' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const updateMemberToPartner = createAsyncThunk<
  // Return type of the payload creator
  MemberPartnerRelation,
  // First argument to the payload creator
  { memberId: string, partnerId: string, data: AddMemberToPartnerInput },
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
  }>(
    'dashboard/updateMemberToPartner',
    async (request, { rejectWithValue }) => {
      try {
        console.log(request);
        const memberPartnerRelation = await dashboardAPI
          .updateMemberToPartner(request.memberId, request.partnerId, request.data);
        return (memberPartnerRelation || undefined) as MemberPartnerRelation;
      } catch (err) {
        console.log(err);
        const responseError: ResponseError = { errorMessage: 'something went wrong' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const getSelectedPartnersForMember = createAsyncThunk<
  // Return type of the payload creator
  MemberPartnerRelation[],
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'dashboard/getSelectedPartnersForMember',
    async (_, { rejectWithValue, getState }) => {
      const { memberSession: { memberId } } = getState().onboarding;
      try {
        const response = await dashboardAPI.getSelectedPartnersForMember(memberId);
        const memberPartnerRelations = response || [];
        const selectedPartners = (memberPartnerRelations as MemberPartnerRelation[]);
        return selectedPartners;
      } catch (err) {
        console.log(err);
        const responseError: ResponseError = { errorMessage: 'failed to get partners for member' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

// endpoint: payments/:memberId/year
export const getYearlyPayments = createAsyncThunk<
  // Return type of the payload creator
  YearlyPayments[],
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'dashboard/getYearlyPayments',
    async (_, { rejectWithValue, getState }) => {
      const { memberSession: { memberId } } = getState().onboarding;
      try {
        const response = await dashboardAPI.getYearlyPaymentsById(memberId);
        const paymentsHistory = response || [];
        const payments = (paymentsHistory as YearlyPayments[]);
        return payments;
      } catch (err) {
        console.log(err);
        const responseError: ResponseError = { errorMessage: 'failed to get year payments' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const getMemberConfig = createAsyncThunk<
  // Return type of the payload creator
  MemberConfig,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'dashboard/getMemberConfig',
    async (_, { rejectWithValue, getState }) => {
      try {
        const { memberSession: { memberId } } = getState().onboarding;
        let memberConfig = await dashboardAPI.getMemberConfig(memberId);
        if (!memberConfig) {
          memberConfig = await dashboardAPI.createMemberConfig({
            showFollowTopicsCard: true,
          }, memberId);
        }
        return memberConfig as MemberConfig;
      } catch (err) {
        console.log(err);
        const responseError: ResponseError = { errorMessage: 'failed to get member config' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );
