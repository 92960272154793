import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  FieldValues,
  useForm,
} from 'react-hook-form';
import {
  Alert,
  Backdrop,
  CircularProgress,
  Snackbar,
  Typography,
  useTheme,
} from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import {
  addFromAnswers,
  addMemberInformation,
  changeFormIndex,
  selectBusinessInformationQuestions,
  selectCurrentFormIndex,
  selectMemberInformation,
  selectMemberPartnerRelation,
  selectMemberSession,
  selectOnboardingForm,
  updateFormValidState,
  // updateNavigationFromOnboarding,
} from '../../../store/slices/onboardingSlice';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../store/hooks';
import { ONBOARDING_FORMS } from '../constants';
import InputComponent from '../../../common/QuestionComponent';
import { Question, QuestionOption } from '../../../common/models/Question';
import { EnrollmentRequest } from '../../../common/models/EnrollmentRequest';
import { Member } from '../../../common/models/Member';
import { Answer } from '../../../common/models/Answer';
import { enrollMemberToReward } from '../../../store/thunks/onboardingThunk';
import { useSolutionOrg } from '../../../config/useSolutionOrg';
import { useOnboardEventTracking } from '../../../common/useOnboardEventTracking';
import { HOME_DEPOT_SETUP_OR_SKIP_ROUTE, HOME_DEPOT_SETUP_WEB } from '../../../routes/namedRoutes';
import { ReactComponent as HomeDepotLogo } from '../../../assets/images/hd_logo.svg';
import { useFeatureFlags } from '../../../config/FeatureFlagsProvider';

export interface OnboardBusinessInformationProps {
  setIsOnboarding?: ((value: boolean) => void) | null
  submitOnboardForm?: boolean;
  onlyHomeDepotIsPartner?: boolean
  isIframe?: boolean
}
export function OnboardBusinessInformation({
  setIsOnboarding, onlyHomeDepotIsPartner, isIframe, submitOnboardForm = true,
}:
  OnboardBusinessInformationProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const businessInformationQuestions = useAppSelector(selectBusinessInformationQuestions);
  const currentFormIndex = useAppSelector(selectCurrentFormIndex);
  const questionTitleAndIdMap = new Map<string, string>();
  businessInformationQuestions.forEach((question: Question, index: number) => {
    questionTitleAndIdMap.set(question.meta?.memberProperty as string ||
      question.title + index, question.id);
  });
  const memberInformation = useAppSelector(selectMemberInformation);
  const memberSession = useAppSelector(selectMemberSession);
  const memberPartnerRelation = useAppSelector(selectMemberPartnerRelation);
  const onboardingForm = useAppSelector(selectOnboardingForm);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const trackOnboardEvent = useOnboardEventTracking();
  const { envRewardProgramId, envSolutionOrgId } = useSolutionOrg();
  const navigate = useNavigate();
  const { behrFlag } = useFeatureFlags();

  const {
    handleSubmit,
    control,
    formState,
    trigger,
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    dispatch(updateFormValidState(formState.isValid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  const getDefaultValue = (question: Question) => {
    switch (question.meta?.behavior) {
      case 'autoComplete':
        return question.options?.find((option: QuestionOption) => option.display ===
          memberInformation.businessMainIndustry);
      case 'dragAndDrop':
        return question.options;
      case 'singleSelect':
        return question.options?.find((option: QuestionOption) => option.value ===
          memberInformation.companyRoleId);
      default: return null;
    }
  };

  const prepareEnrollmentRequest = (memberInfo: Member, formAnswers: Answer[]) => {
    const {
      id, email, companyRole, company, ...enrolledMember
    } = memberInfo;
    const request: EnrollmentRequest = {
      memberId: memberInfo.id,
      rewardProgramId: memberSession.rewardProgramId || (envRewardProgramId as string) || '',
      member: enrolledMember,
      forms: [
        {
          formId: onboardingForm.id,
          answers: formAnswers,
        },
      ],
      partners: [
        {
          partnerId: memberPartnerRelation?.partnerId || process.env.REACT_APP_PARTNER_ID || '',
          partnerMemberEmail: memberPartnerRelation?.memberEmail || undefined,
        },
      ],
    };
    if (behrFlag) {
      // auto enroll for Behr if enrolling in home depot
      request.partners = [...request.partners, {
        partnerId: process.env.REACT_APP_BEHR_PARTNER_ID || '',
      }];
    }
    console.log('request.partners ', request.partners);
    const solutionOrgId = memberSession.solutionOrgId || (envSolutionOrgId as string) || '';
    dispatch(enrollMemberToReward({ solutionOrgId, request }))
      .then(unwrapResult)
      .then(() => {
        setOpenSuccessSnackbar(true);
        setOpenErrorSnackbar(false);

        // send a rewards everpro signup completed event
        trackOnboardEvent('rewards_everpro_signup_completed');

        if (onlyHomeDepotIsPartner) {
          if (isIframe) {
            // move to the home depot splash screen
            navigate(HOME_DEPOT_SETUP_OR_SKIP_ROUTE);
          } else {
            // navigate to home depot setup for web
            navigate(HOME_DEPOT_SETUP_WEB);
          }
        } else {
          // keeping this for legacy joist rewards
          window.parent.postMessage({
            type: 'OPTIMUS_REWARDS_EVERPRO_SIGNUP_COMPLETED',
            payload: {
              event: {
                name: 'rewards_everpro_signup_completed',
              },
            },
          }, '*');
        }
      })
      .catch((error: unknown) => {
        setOpenSuccessSnackbar(false);
        setOpenErrorSnackbar(true);
        setLoading(false);
        if (setIsOnboarding) setIsOnboarding(false);
        // send a rewards everpro signup failed event
        trackOnboardEvent('rewards_everpro_signup_failed', { error });
      });
  };

  const onSubmit = (data: FieldValues) => {
    if (data) {
      const updatedMemberInfo = { ...memberInformation };
      const answers: Answer[] = [];
      Object.keys(data).forEach((key: string) => {
        const questionId = questionTitleAndIdMap.get(key);
        const question = businessInformationQuestions
          .find((item) => item.id === questionId) as Question;
        if (question?.meta?.memberProperty) { // goes to member object
          const prop = question?.meta?.memberProperty;
          updatedMemberInfo[prop as keyof Member] = data[key]?.value || '';
        } else {
          // goes to answer object
          const answer: Answer = {
            questionId: questionId || '',
            value: JSON.stringify(data[key]),
          };
          answers.push(answer);
        }
      });
      if (submitOnboardForm) {
        setLoading(true);
        prepareEnrollmentRequest(updatedMemberInfo, answers);
      } else {
        // send a rewards everpro details updated event
        trackOnboardEvent('rewards_everpro_trade_and_business_goal_updated');
        dispatch(addMemberInformation(updatedMemberInfo));
        dispatch(addFromAnswers(answers));
        dispatch(changeFormIndex(currentFormIndex + 1));
      }
    }
  };

  const handleControlChange = (question: Question) => {
    trackOnboardEvent(
      'onboarding_question_response_updated',
      {
        questionId: question.id,
        questionTitle: question.title,
      },
    );
  };

  const handleClose = () => {
    setOpenErrorSnackbar(false);
    setOpenSuccessSnackbar(false);
  };

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          mt: 7,
        }}
      >
        <form id={ONBOARDING_FORMS.businessInformation.id} onSubmit={handleSubmit(onSubmit)}>
          <Snackbar
            open={openSuccessSnackbar}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert severity="success" sx={{ width: '100%' }}>
              {t('success.generalMessage')}
            </Alert>
          </Snackbar>
          <Snackbar
            open={openErrorSnackbar}
            autoHideDuration={4000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              {t('error.generalMessage')}
            </Alert>
          </Snackbar>
          {!setIsOnboarding &&
            <Backdrop
              sx={{ color: theme.palette.common.white, zIndex: () => theme.zIndex.drawer + 1 }}
              open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>}
          <Box>
            {businessInformationQuestions
              .map((question: Question, index: number) => {
                const controllerName = question.meta?.memberProperty as string ||
                  question.title + index;
                return (
                  <Box key={question.id}>
                    <Typography sx={{
                      mt: 3,
                      textAlign: 'left',
                      mb: '2px',
                      color: theme.palette.color2.main,
                    }}>
                      {t(`onboarding.questionPlaceholder.${question.title}`)}
                    </Typography>
                    <Controller
                      name={controllerName}
                      control={control}
                      rules={question.meta?.rules || {}}
                      defaultValue={getDefaultValue(question)}
                      render={(
                        {
                          field: {
                            onChange, value, onBlur,
                          },
                        },
                      ) => (
                        <div>
                          <InputComponent
                            type={question.type}
                            onChange={(e) => {
                              onChange(e); // Ensure React Hook Form's internal state is updated
                              handleControlChange(question);
                            }}
                            inputValue={value}
                            meta={question.meta}
                            options={question.options}
                            placeholderText={question.title ? question.title : ''}
                            subTitle={question.meta?.subTitle as string}
                            onBlur={onBlur}
                          />
                        </div>
                      )
                      }
                    />
                    {
                      formState.touchedFields[controllerName] &&
                      formState.errors[controllerName]?.message &&

                      <Alert
                        sx={() => ({
                          backgroundColor: theme.palette.grey[100],
                          color: theme.palette.error.main,
                          p: 0,
                        })}
                        severity='error'>
                        {formState.errors[controllerName]?.message?.toString()}
                      </Alert>}
                  </Box>
                );
              })}
          </Box>
          {!isIframe && formState.isValid && (
            <Box
              sx={{
                textAlign: 'center',
                my: 3,
                backgroundColor: theme.palette.color14.main,
                padding: theme.spacing(2.5),
                borderRadius: theme.spacing(1.5),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ marginRight: theme.spacing(2.5), display: 'flex' }}>
                <HomeDepotLogo width={theme.spacing(9.5)} height={theme.spacing(9.5)} />
              </Box>
              <Typography
                variant='h4'
                sx={{
                  lineHeight: theme.spacing(2.6),
                  color: theme.palette.common.white,
                  textAlign: 'left',
                }}
              >
                {t('onboarding.questionPlaceholder.More Info Done')}
              </Typography>
            </Box>
          )}
        </form>
      </Box>
    </Box>
  );
}
