import {
  Alert,
  Box, Card, CardContent, CircularProgress, FormControl,
  FormControlLabel, List,
  ListItem, ListItemText,
  Radio, RadioGroup, Snackbar, TextField, Typography, useTheme,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import { AddMemberToPartnerInput } from '@optimus/models';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useCompanyEnrollmentInfo } from '../../../common/useCompanyEnrollmentInfo';
import OnboardingLayout from './common/OnboardingLayout';
import CallToActionButton from '../../../common/CallToActionButton';
import { ReactComponent as NewTabIcon } from '../../../assets/images/new_tab_icon.svg';
import { useOnboardEventTracking } from '../../../common/useOnboardEventTracking';
import { emailRegex } from '../../../common/constants';
import { updateMemberToPartner } from '../../../store/thunks/dashboardThunk';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectMemberSession, updateNavigationFromOnboarding } from '../../../store/slices/onboardingSlice';
import VideoPlayer from '../../../common/VideoPlayer';

interface IFormInputs {
  accountStatus: string;
  email: string
}

interface CustomButtonProps {
  onBtnClick?: () => void;
  disabled?: boolean;
  btnText: string | JSX.Element;
  smallerMargin?: boolean;
  type: 'button' | 'submit' | 'reset' | undefined
}

export const SHOW_SIGN_IN_OR_SIGN_UP_ACTION = 'Sign in or sign up';
export const SHOW_IS_DONE_ACTION = 'Did you complete all the steps?';
export const SHOW_EMAIL_ACTION = 'Enter email';

export function OnboardHDHomeWeb() {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    register, handleSubmit, control, formState, setValue, clearErrors,
  } = useForm<IFormInputs>({ mode: 'onChange' });
  const [showSignInOrSignUp, setShowSignInOrSignUp] = useState(false);
  const [showIsDone, setShowIsDone] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [hdEmail, setHdEmail] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const trackOnboardEvent = useOnboardEventTracking();
  const { memberId } = useAppSelector(selectMemberSession);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const signInVideoUrl = 'https://d2o7bp9oz3gksv.cloudfront.net/sign_in_flow_044a3d8ae3.mp4';
  const signUpVideoUrl = 'https://d2o7bp9oz3gksv.cloudfront.net/sign_up_flow_beef0edde8.mp4';

  // check company enrollment status
  useCompanyEnrollmentInfo({
    calledFromOnboardingComponent: false,
    calledFromHomeDepotSetUpWeb: true,
  });

  // Watch the selected radio button value
  // const selectedOption = watch('accountStatus');

  const setSecondaryCardState = (triggerAction: string) => {
    switch (triggerAction) {
      case SHOW_SIGN_IN_OR_SIGN_UP_ACTION:
        setShowSignInOrSignUp(true);
        setShowIsDone(false);
        setShowEmail(false);
        break;
      case SHOW_IS_DONE_ACTION:
        setShowIsDone(true);
        setShowEmail(false);
        setShowSignInOrSignUp(false);
        break;
      case SHOW_EMAIL_ACTION:
        setShowEmail(true);
        setShowIsDone(false);
        setShowSignInOrSignUp(false);
        break;
      default:
        break;
    }
  };

  const onSubmit = (data:IFormInputs) => {
    if (data.email) {
      setLoading(true);
      const request: AddMemberToPartnerInput = {
        partnerMemberEmail: data.email,
      };

      trackOnboardEvent('clicked-hd-card-confirm-email', {
        hdEmail: data.email,
      });

      dispatch(updateMemberToPartner({
        memberId,
        partnerId: process.env.REACT_APP_HOME_DEPOT_PARTNER_ID || '',
        data: request,
      }))
        .then(unwrapResult)
        .then(() => {
          setOpenErrorSnackbar(false);

          // navigate to content home page
          dispatch(updateNavigationFromOnboarding(true));
          navigate('/home', {
            state: {
              fromOnboarding: true,
            },
          });
        })
        .catch((error: unknown) => {
          setOpenErrorSnackbar(true);
          setLoading(false);
        });
    }
  };

  const handleErrorClose = () => {
    setOpenErrorSnackbar(false);
  };

  // Handle the radio button change
  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (hdEmail) {
      setHdEmail('');
      setValue('email', ''); // reset the email field
      clearErrors('email'); // Clear any existing validation errors for the email field
    }
    setSelectedOption(e.target.value);
    setSecondaryCardState(SHOW_SIGN_IN_OR_SIGN_UP_ACTION);
    trackOnboardEvent('selected-if-existing-hd-proxtra-account', {
      existingProXtraAccount: e.target.value,
    });
  };

  const onLaunchClick = () => {
    window.open('https://www.homedepot.com/myaccount/proXtraPricing', '_blank');
    setSecondaryCardState(SHOW_IS_DONE_ACTION);
    trackOnboardEvent('clicked-hd-card-sign-in');
    trackOnboardEvent('clicked-hd-card-enter-code');
  };

  const onYesDoneClick = () => {
    setSecondaryCardState(SHOW_EMAIL_ACTION);
    trackOnboardEvent('clicked-hd-card-setup-done');
  };

  const onNotDoneClick = () => {
    setSecondaryCardState(SHOW_SIGN_IN_OR_SIGN_UP_ACTION);
    trackOnboardEvent('clicked-hd-card-setup-reset');
  };

  const CustomButton = ({
    onBtnClick,
    btnText,
    disabled,
    smallerMargin,
    type,
  }:CustomButtonProps) => (<CallToActionButton
    text={btnText}
    type={type}
    onBtnClick={onBtnClick}
    sx={{
      position: 'static',
      width: '100%',
      marginTop: smallerMargin ? theme.spacing(2.5) : theme.spacing(3.75),
      marginBottom: smallerMargin ? theme.spacing(2.5) : theme.spacing(3.75),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      background: theme.palette.secondary.main,
      borderRadius: theme.spacing(3.75),
      fontWeight: '600',
      fontSize: theme.spacing(2.3),
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '&:disabled': {
        backgroundColor: theme.palette.secondary.main,
        opacity: '0.4',
        color: theme.palette.common.white,
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        marginLeft: '0px',
      },
    }}
    disable={disabled}
  />);

  const SignInOrSignUpCard = () => (
    <Card
              sx={{
                marginTop: theme.spacing(3.75),
                borderRadius: theme.spacing(1.5),
                backgroundColor: theme.palette.common.white,
              }}
              >
                <CardContent sx={{
                  padding: '15px !important',
                }}>
                  <Typography sx={{ textAlign: 'left', fontWeight: 600, mb: theme.spacing(2.5) }}>
                  {t('hdSetUpWeb.signInOrSignUp.heading1')}
                  </Typography>
                  <List sx={{ padding: 0, margin: 0 }}>
                    <ListItem sx={{ p: 0, mb: theme.spacing(1.5) }}>
                      <ListItemText
                        primary={
                          <Typography component="span">
                            {selectedOption === 'yes' ?
                              t('hdSetUpWeb.signInOrSignUp.signInText') :
                              t('hdSetUpWeb.signInOrSignUp.signUpText')
                            }
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <ListItemText
                        primary={
                          <Typography component="span">
                            {/* eslint-disable-next-line max-len */}
                            <Trans i18nKey="hdSetUpWeb.signInOrSignUp.enterCodeText" components={{ strong: <strong /> }} />
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                  <CustomButton
                  type={'button'}
                  onBtnClick={onLaunchClick}
                  btnText={
                  <Typography component='div' display={'flex'} alignItems={'center'}>
                    <Typography sx={{ fontWeight: 600, mr: theme.spacing(1.25) }}>
                    {t('hdSetUpWeb.signInOrSignUp.launchHdBtnText')}
                    </Typography>
                    <NewTabIcon/>
                  </Typography>}
                  smallerMargin
                  />
                  <Typography>
                  <Trans i18nKey="hdSetUpWeb.signInOrSignUp.whenDoneText" components={{ strong: <strong /> }} />
                  </Typography>
                </CardContent>
              </Card>
  );

  const IsDoneCard = () => (
    <Card
              sx={{
                marginTop: theme.spacing(3.75),
                borderRadius: theme.spacing(1.5),
                backgroundColor: theme.palette.common.white,
              }}
              >
                <CardContent sx={{
                  padding: '15px !important',
                }}>
                  <Typography sx={{ textAlign: 'left', fontWeight: 600 }}>
                  {t('hdSetUpWeb.isDone.heading1')}
                  </Typography>
                  <CustomButton
                  type={'button'}
                  onBtnClick={onYesDoneClick}
                  btnText={
                    <Typography component='span' sx={{ fontWeight: 600, mr: theme.spacing(1.25) }}>
                    {t('hdSetUpWeb.isDone.yesDone')}
                    </Typography>}
                  smallerMargin
                  />
                  <Box display={'flex'} justifyContent={'center'}>
                  <Typography component='div'sx={{
                    fontWeight: 600,
                    cursor: 'pointer',
                    width: 'fit-content',
                    color: theme.palette.secondary.main,
                  }}
                  onClick={onNotDoneClick}
                  >
                  {t('hdSetUpWeb.isDone.noDone')}
                  </Typography>
                  </Box>
                </CardContent>
              </Card>

  );

  return (
    <OnboardingLayout
    customDesktopBanner={
      (showSignInOrSignUp || showIsDone || showEmail) ?
      <VideoPlayer
      videoUrl={selectedOption === 'yes' ? signInVideoUrl : signUpVideoUrl}
        optionalVideoContainerStyle={{
          marginTop: theme.spacing(6), // Video Margin Top to fit screen
          marginLeft: theme.spacing(18.75),
          marginRight: 'auto',
          background: theme.palette.common.black,
          maxWidth: theme.spacing(81),
          borderRadius: theme.spacing(2.41),
          padding: theme.spacing(5),
          // height: '60vh',
        }}
       /> : null
    }
    >
      <Box
        sx={{
          mt: theme.spacing(-4),
        }}
      >
        <Box>
          <Snackbar
            open={openErrorSnackbar}
            autoHideDuration={4000}
            onClose={handleErrorClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              {t('error.generalMessage')}
            </Alert>
          </Snackbar>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h2"
            sx={{
              fontWeight: 500,
              textAlign: 'center',
              color: theme.palette.color3.main,
            }}
            >
              {t('hdSetUpWeb.heading1')}
            </Typography>
            <Typography variant="h2"
            sx={{
              fontWeight: 500,
              textAlign: 'center',
              color: theme.palette.color3.main,
              mb: theme.spacing(3.75),
            }}
            >
              {t('hdSetUpWeb.heading2')}
            </Typography>

            <Card
            sx={{
              borderRadius: theme.spacing(1.5),
              backgroundColor: theme.palette.common.white,
              padding: theme.spacing(0.5),
            }}
            >
              <CardContent>
                <FormControl component="fieldset">
                  <Typography sx={{
                    fontWeight: 600,
                    textAlign: 'left',
                    color: theme.palette.color16.main,
                    mb: theme.spacing(2.5),
                  }} >
                    {t('hdSetUpWeb.heading3')}
                  </Typography>

                  <Controller
                      name="accountStatus"
                      control={control}
                      defaultValue={selectedOption}
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          aria-label="account status"
                          row
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleRadioChange(e); // Call the handler on change
                          }}
                          sx={{
                            justifyContent: 'space-between',
                            width: '100%',
                            '& .MuiRadio-root': {
                              '&:hover': {
                                backgroundColor: theme.palette.secondary.light,
                              },
                              '&.Mui-checked': {
                                color: theme.palette.secondary.main,
                              },
                            },
                          }}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label={<Typography sx={{ color: theme.palette.color17.main }}>
                              Yes
                            </Typography>}
                            {...register('accountStatus')}
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label={<Typography sx={{ color: theme.palette.color17.main }}>
                              No
                            </Typography>}
                            {...register('accountStatus')}
                          />
                          <FormControlLabel
                            value="unsure"
                            control={<Radio />}
                            label={<Typography sx={{ color: theme.palette.color17.main }}>
                              I'm not sure
                            </Typography>}
                            {...register('accountStatus')}
                          />
                        </RadioGroup>
                      )}/>
                </FormControl>
              </CardContent>
            </Card>
            {showSignInOrSignUp && <SignInOrSignUpCard />}
            {showIsDone && <IsDoneCard/>}
            {showEmail && <Card
              sx={{
                marginTop: theme.spacing(3.75),
                borderRadius: theme.spacing(1.5),
                backgroundColor: theme.palette.common.white,
              }}
              >
                <CardContent sx={{
                  padding: '15px !important',
                }}>
                  <Typography sx={{ textAlign: 'left', fontWeight: 600, mb: theme.spacing(2.5) }}>
                  {t('hdSetUpWeb.enterEmail.heading1')}
                  </Typography>
                  <Box>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue={hdEmail}
                      render={({
                        field: {
                          onChange, onBlur, value,
                        },
                      }) => <TextField
                          value={value} // Set the value directly
                          onChange={(event) => {
                            onChange(event);
                            setHdEmail(event.target.value);
                          }}
                          onBlur={onBlur}
                          placeholder={'Enter your email address'}
                          InputProps={{
                            sx: {
                              borderRadius: theme.spacing(6.25),
                              border: `1px solid ${theme.palette.color8.main}`,
                              background: theme.palette.color7.main,
                              fontWeight: '400',
                              fontSize: theme.spacing(2.25),
                              paddingLeft: theme.spacing(2),
                            },
                          }}
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root.Mui-focused': {
                              '& > fieldset': {
                                borderColor: formState?.errors?.email && formState.errors.email.type !== 'required' ?
                                  theme.palette.primary.main : theme.palette.secondary.main,
                              },
                            },
                          }}
                        />}
                      rules={{
                        required: 'email is required',
                        pattern: {
                          value: emailRegex,
                          message: `${t('error.invalidEmail2')}`,
                        },
                      }}
                    />
                    {(formState?.errors?.email && formState.errors.email.type !== 'required') &&
                      <Alert
                        icon={false}
                        sx={{
                          background: theme.palette.common.white,
                          color: theme.palette.error.main,
                          p: 0,
                        }}
                        severity='error'>
                        {formState?.errors.email.message}
                      </Alert>}
                  </Box>
                </CardContent>
              </Card>}
            <CustomButton
            type={'submit'}
            btnText={
              loading ?
                <CircularProgress color="inherit" size={24} /> :
                `${t('button.completeHdWebSetup')}`
            }
            disabled={!hdEmail || !formState.isValid || loading}
            />
          </form>
          <Box display={'flex'} justifyContent='center' mt={2}>
          {[...Array(3).keys()].map((val) => (
            <Box
              key={val}
              sx={{
                height: theme.spacing(1.125),
                width: theme.spacing(1.125),
                backgroundColor: theme.palette.color18.main,
                ...(val + 1 === 3 && {
                  backgroundColor: theme.palette.secondary.main,
                }),
                borderRadius: '50%',
                marginRight: theme.spacing(1.5),
                ...(val + 1 === 3 && { marginRight: '0px' }),
              }}
            />
          ))}
          </Box>
        </Box>
      </Box>
    </OnboardingLayout>
  );
}
