import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CircularProgress, Container } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectMemberSession } from '../store/slices/onboardingSlice';
import { logout } from '../store/thunks/authenticationThunk';
import { isOnboarding } from '../routes/namedRoutes';

interface AuthenticatedRouteProps{
  children: JSX.Element
}

/* const tokenExpired = (tokenExpiry: string | null): boolean => {
  if (!tokenExpiry) {
    // If tokenExpiry is not provided, consider it as expired
    return true;
  }

  try {
    const tokenExpiryDate = new Date(tokenExpiry);
    const currentDate = new Date();

    // Use the getTime() method to compare timestamps, which is more reliable
    // check if expired
    if (currentDate.getTime() > tokenExpiryDate.getTime()) {
      return true;
    }
  } catch (error) {
    // Handle the parsing error, log it, and consider the token expired
    console.error('Error parsing tokenExpiry:', error);
    return true;
  }

  return false;
}; */

export default function AuthenticatedRoute({ children }: AuthenticatedRouteProps) {
  const { accessToken, memberId } = useAppSelector(selectMemberSession);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const token = accessToken || searchParams.get('accessToken');

  useEffect(() => {
    if (!token) {
      dispatch(logout({ isOnboarding: isOnboarding(), navigate }));
    } else {
      setIsLoading(false);
      // TODO is there a better place for this?
      if (globalThis.heap) { globalThis.heap.identify(memberId); }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    isLoading ? (
      <Container sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <CircularProgress color="inherit" size={40} />
      </Container>
    ) : (
      <>
        {children}
      </>
    )
  );
}
