import { useMediaQuery, useTheme } from '@mui/material';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import AccountIcon from '@mui/icons-material/PersonOutline';
import RewardsIcon from '@mui/icons-material/SellOutlined';
import FilledHomeIcon from '@mui/icons-material/Home';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FilledAccountIcon from '@mui/icons-material/Person';
import FilledRewardsIcon from '@mui/icons-material/Sell';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { DashboardDesktopLayout } from './DashboardDesktopLayout';
import { DashboardMobileLayout } from './DashboardMobileLayout';
import ContentSearchView from '../Content/ContentSearchView';
import { DashboardRewardBanner } from './DashboardRewardBanner';
import { useFeatureFlags } from '../../config/FeatureFlagsProvider';
import { TopicsCard } from './TopicsCard';
import { HDSetupFlowCard } from './HDOnboardingFlow/HDSetupFlowCard';
import { useAppDispatch } from '../../store/hooks';
import { getTopTopics } from '../../store/thunks/contentThunk';

interface DashboardLayoutProps {
  children: JSX.Element;
}

export function DashboardLayout({
  children,
}: DashboardLayoutProps) {
  const theme = useTheme();
  // treating anything above 'md' as desktop
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { searchFlag, hdOnboardingForSNFlag } = useFeatureFlags();

  let rightColumnElements: JSX.Element[] = [];
  let searchElem;
  const hdSetupFlowCard = <HDSetupFlowCard hideDoneState dashboardLayout />;
  const topicsCard = <TopicsCard />;
  const rewardBanner = <DashboardRewardBanner />;
  if (searchFlag) {
    searchElem = <ContentSearchView onSubmit={(value) => {
      const params = new URLSearchParams();
      params.append('query', value || '');
      const route = `/search?${params.toString()}`;
      console.log('going to ', route);
      navigate(route);
    }} />;
    rightColumnElements = [searchElem, topicsCard];
  } else {
    rightColumnElements = [rewardBanner];
  }

  // push Home Depot instructions card at 2nd position
  if (hdOnboardingForSNFlag) {
    rightColumnElements.splice(1, 0, hdSetupFlowCard);
  }

  useEffect(() => {
    dispatch(getTopTopics());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fullNavElems = [
    {
      name: 'Home',
      path: '/home',
      Outline: HomeIcon,
      Filled: FilledHomeIcon,
      rightColumnElements,
      isCurrent: false,
      isHidden: false,
    },
    {
      name: 'Saved',
      path: '/saved',
      Outline: BookmarkBorderOutlinedIcon,
      Filled: BookmarkIcon,
      isHidden: false,
      noFullWidth: true,
    },
    {
      name: 'Rewards',
      path: '/rewards',
      Outline: RewardsIcon,
      Filled: FilledRewardsIcon,
      isHidden: false,
    },
    {
      name: 'My Account',
      path: '/myaccount',
      Outline: AccountIcon,
      Filled: FilledAccountIcon,
      isHidden: false,
    },
  ];

  if (searchFlag) {
    fullNavElems.push(
      {
        name: 'Search',
        path: '/search',
        Outline: HomeIcon,
        Filled: FilledHomeIcon,
        rightColumnElements: [topicsCard],
        // This is for mapping the extra column only.
        isHidden: true,
        isCurrent: false,
      },
    );
  }

  const currentRoute = fullNavElems.find((elem) => elem.path === location.pathname);
  if (currentRoute) {
    // highlight Home navigation icon if search is active
    if (currentRoute.name === 'Search') {
      const homeRoute = fullNavElems.find((elem) => elem.name === 'Home');
      if (homeRoute) homeRoute.isCurrent = true;
    } else {
      currentRoute.isCurrent = true;
    }
  }

  const navElems = fullNavElems.filter((elem) => !elem.isHidden);

  if (isDesktop) {
    return (<DashboardDesktopLayout
      navElems={navElems}
      rightColumnElements={currentRoute?.rightColumnElements}
      noFullWidth={currentRoute?.noFullWidth}
    >
      {children}
    </DashboardDesktopLayout>);
  }

  return (<DashboardMobileLayout navElems={navElems}>
    {children}
  </DashboardMobileLayout>);
}
