import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useSolutionOrg } from '../config/useSolutionOrg';
import { selectMemberSession } from '../store/slices/onboardingSlice';
import { ResponseError, getCompanyEnrollmentInformation } from '../store/thunks/onboardingThunk';
import { useOnboardEventTracking } from './useOnboardEventTracking';
import {
  HOME_DEPOT_SETUP_OR_SKIP_ROUTE, HOME_DEPOT_SETUP_WEB, HOME_ROUTE, ONBOARDING_BASE_ROUTE,
} from '../routes/namedRoutes';
import { getHomeDepotMemberRelation } from '../store/thunks/dashboardThunk';

interface useCompanyEnrollmentInfoProps {
  calledFromOnboardingComponent: boolean;
  redirectPath?: string;
  calledFromHomeDepotSetUpWeb?: boolean
  calledFromHomeDepotSetUpIframe?: boolean;
}
export function useCompanyEnrollmentInfo({
  calledFromOnboardingComponent,
  calledFromHomeDepotSetUpWeb,
  calledFromHomeDepotSetUpIframe,
  redirectPath,
}: useCompanyEnrollmentInfoProps) {
  const trackOnboardEvent = useOnboardEventTracking();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { envRewardProgramId, isIframe } = useSolutionOrg();
  const memberSession = useAppSelector(selectMemberSession);

  useEffect(() => {
    dispatch(getCompanyEnrollmentInformation(
      {
        rewardProgramId: envRewardProgramId as string,
        memberId: memberSession.memberId,
      },
    ))
      .then(unwrapResult)
      .then((companyEnrollmentInfoResponse) => {
        if (companyEnrollmentInfoResponse?.status) { // user already enrolled in reward
          // Get member home depot partner relation, if it exists
          dispatch(getHomeDepotMemberRelation())
            .then(unwrapResult)
            .then((memberHdRelation) => {
              // Redirect to home depot setup if:
              // 1. member has home depot as partner
              // 2. member is not enrolled yet in home depot
              if (memberHdRelation && !(memberHdRelation.partnerMemberKey ||
                memberHdRelation.partnerMemberEmail ||
                memberHdRelation.partnerCodeEnteredInMobile)) {
                // web user and hook not called from home depot setup web page
                if (!isIframe && !calledFromHomeDepotSetUpWeb) {
                  navigate(HOME_DEPOT_SETUP_WEB);
                }

                // iframe user and hook not called from home depot setup iframe page
                if (isIframe && !calledFromHomeDepotSetUpIframe) {
                  navigate(HOME_DEPOT_SETUP_OR_SKIP_ROUTE);
                }
              } else if (
                calledFromHomeDepotSetUpWeb ||
                calledFromHomeDepotSetUpIframe ||
                calledFromOnboardingComponent) {
                navigate(redirectPath || HOME_ROUTE);
              }
            })
            .catch((error: ResponseError) => {
              trackOnboardEvent('get-member-hd-relation-failed', { error }, error.apiResponseErrorStatus);
            });
        } else if (!calledFromOnboardingComponent) { // user has not yet enrolled in reward
          navigate(redirectPath || ONBOARDING_BASE_ROUTE);
        }
      })
      .catch((error: ResponseError) => {
        trackOnboardEvent('get-company-enrollment-information-failed', { error }, error.apiResponseErrorStatus);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
